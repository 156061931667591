@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Droid+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');

body {
  scroll-behavior: smooth;
  margin: 0;
  font-family: "Open Sans", sans-serif !important;
  /* background-color: #f8f9fa !important; */
  /* background-color: #fdc208 !important; */
  min-height: 100vh !important;
  background-color: #f5f5f8 !important;
}

.bg-img1::before,
.bg-img::before {
  content: '';
  background: url(../src/assets/images/top-graph2.png) no-repeat top center/contain;
  position: absolute;
  opacity: 0.3;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  /* z-index: -1; */
}

.banner-title.text-light {
  font-family: "Nunito", sans-serif;
  font-size: 40px;
  font-weight: 700;
}

.sec-heading {
  font-size: 20px;
  font-weight: bold;
}

.banner-ad {
  min-height: 200px;
}
.banner-product {
  min-height: 300px;
}

.btn-link {
  border: 1px solid #fff;
  padding: 10px 20px;
  text-decoration: none;
}

.discount-p {
  display: inline-flex;
  width: 48px;
  height: 35px;
  background: #fcc90c;
  border-radius: 14%;
  align-items: center;
  justify-content: center;
  font-size: 20px;

  font-weight: bold;
}

.carousel-inner {

  border-radius: 5px;
}

.bg-img::after {
  content: '';
  background: url(../src/assets/images/bottom-graph2.png) no-repeat bottom center/contain;
  position: absolute;
  opacity: 0.3;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  /* z-index: -1; */
}

.fs-10 {
  font-size: 10px;
}

.fs-11 {
  font-size: 11px;
}

.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}

.bg-pages {
  /* background-color: #f5f5f8 !important; */
  background-color: #fdc208 !important;
  height: 100%;
}

.sub-heading {
  font-size: 18px;
}

/* input */
*:focus,
input:focus,
input.form-control:focus,
.accordion-button:focus,
.form-select:focus,
textarea.form-control:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

.form-control,
.form-control:focus,
.form-control:active,
.form-control:hover {
  border: none;
}

.form-select:focus {
  border: none;
}

.form-control {
  /* box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12); */
  /* box-shadow: 0 1px 3px 0 #d4d9e1; */
  border-radius: 10px;
}

.form-control,
.form-select {
  font-size: 14px;
}

.form-floating>label {
  font-size: 12px;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s !important;
}

.shadow-box {
  box-shadow: 0 1px 3px 0 #d4d9e1 !important;
}

.text-blue {
  color: #506070 !important;
}

.text-yellow {
  color: #fdc208 !important;
}

.text-red {
  color: #e73838 !important;
}

.text-green {
  color: #46a846 !important;
}

.bg-yellow {
  background-color: #fdc208 !important;
  border-color: #fdc208 !important;
}

.bg-light-yellow {
  background-color: #fdc208d4 !important;
  border-color: #fdc208 !important;
}

.bg-light-red {
  background: #ed5575 !important;
  border-color: #ed5575 !important;
}

.bg-light-green {
  background: #46a846 !important;
  border-color: #46a846 !important;
}

.bg-light-blue {
  background: #506070 !important;
  border-color: #506070 !important;
}

.bg-light-grey {
  background: #a8a8a8d4 !important;
  border-color: #b7b7b7 !important;
}


/* table start */

tbody,
td,
tfoot,
th,
thead,
tr {
  border: none;
  vertical-align: middle;
  font-size: 14px;
}

/* table end */

/*  searchbar start */
.search-form {
  position: relative;
  border-radius: 20px;
}

.search-form .search-icon {
  position: absolute !important;
  top: 10px;
  left: 15px;
  color: #506070;
  /* color: #9ca3af; */
  cursor: pointer;
}

.search-form .left-pan {
  position: absolute !important;
  right: 17px;
  top: 7px;
  padding: 2px !important;
  border-left: 1px solid #9ca3af !important;
  cursor: pointer;
  color: #506070 !important;
  /* color: #9ca3af !important; */
  font-size: 14px;
}

/* .left-pan{
    padding-left: 7px;
} */

.pan .fa-microphone {
  padding-left: 10px;
}

.form-input {
  /* text-indent: 25px; */
  font-size: 14px;
}

.form-input::placeholder {
  color: #adadad !important;
  /* text-transform: lowercase; */
}


.searchInput {
  border: 1px solid #ced4da;
}

.search-Icon {
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 15px;
  color: #506070 !important;
  /* color: #9ca3af !important; */
  font-size: 14px;
}

/* searchbar end */


/* accordion  */

.accordion-button {
  padding: 8px;
  padding-right: 10px;
  color: #000000 !important;
  border-radius: 10px !important;

}

.accordion {
  --bs-accordion-active-bg: none;
  --bs-accordion-border-color: none;
  /* --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"); */
}

.accordion-button::after,
.accordion-button:not(.collapsed)::after {
  /* background-image: var(--bs-accordion-btn-active-icon); */
  background-image: url(../src/assets/images/down-chevron.png);
}


.accordion-item {
  /* box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12); */
  box-shadow: 0 1px 3px 0 #d4d9e1;
  margin-bottom: 8px;
  border-radius: 10px !important;
}

.accordion-item:first-of-type,
.accordion-item:last-of-type,
.accordion-item:last-of-type .accordion-collapse,
.accordion-item:first-of-type .accordion-button,
.accordion-item:last-of-type .accordion-button.collapsed {
  border: none;
}

/* Popular card */

.product-card {
  border-radius: 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border: none;
  height: 100%;
  /* min-height: calc(100vh - 288px); */
  background-color: #f5f5f8 !important;
}

.product-card .card-header {
  border-radius: 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: #ffffff !important;
  border-color: #dee2e6;
}

.card-item {
  display: flex;
  flex-direction: row;
  border: none;
  background-color: #fdc208;
  border-radius: 10px;
  justify-content: center;
  width: 100%;
  align-items: center;
}

.product-card-height {
  overflow: hidden;
  height: calc(100vh - 214px) !important;
  /* height: calc(100vh - 219px) !important; */
  /* height: calc(100vh - 230px) !important; */
  /* height: calc(100vh - 303px) !important; */
  /* max-height: calc(100vh - 408px) !important; */
  /* max-height: calc(100vh - 350px) !important; */
  /* max-height: calc(95vh - 288px) !important; */
  /* max-height: calc(100vh - 232px) !important; */
  overflow-y: auto;
  /* height: 100vh; */
  padding-bottom: 80px;
}

@media (max-width: 280px),
(max-width: 480px),
(max-width: 915px) {

  .product-card-height::-webkit-scrollbar,
  .location-card-height::-webkit-scrollbar {
    display: none;
  }
}

/* Categories Badges */
.badge-items {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  overflow: hidden;
  height: auto;
  overflow-x: auto;
}

.badge-items::-webkit-scrollbar {
  display: none;
}

.next-btn {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(1%, 27%);
}

/* cart */

.card-height {
  overflow: hidden;
  /* height: calc(100vh - 125px) !important; */
  /* max-height: calc(100vh - 150px) !important; */
  /* max-height: calc(100vh - 85px) !important; */
  max-height: calc(100vh - 64px) !important;
  overflow-y: auto;
  padding-bottom: 70px !important;
  height: 100vh;
}

@media (max-width: 280px),
(max-width: 480px),
(max-width: 915px) {
  .card-height::-webkit-scrollbar {
    /* display: none; */
  }
}

.cardItem {
  border-radius: 10px;
  /* border-radius: 0.375rem; */
  border: none;
  box-shadow: 0 1px 3px 0 #d4d9e1 !important;
  /* display: block; */
  /* position: relative; */
  /* text-decoration: none; */
  overflow: hidden;
  color: #212529 !important;
}

.bg-corner-dark {
  background-color: #506070 !important;
  transition: background-color 0.5s ease-in-out;
}

.bg-corner-light {
  background-color: #506070 !important;
}

.go-corner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 30px;
  height: 30px;
  overflow: hidden;
  top: 50%;
  /* right: 6px; */
  right: 3px;
  transform: translate(0%, -50%);
  /* background: #506070 !important; */
  /* border-radius: 20px 0 0 20px; */
  border: none;
  z-index: 2;
  cursor: pointer;
}

.go-arrow-left {
  /* margin-top: -4px;
  margin-right: -4px; */
  color: rgb(255, 255, 255);
  font-family: courier, sans;
  transform: rotate(-180deg);
  transition: transform 0.3s ease-in-out;
}

.go-arrow-right {
  /* margin-top: -4px;
  margin-right: -4px; */
  color: rgb(0, 0, 0);
  font-family: courier, sans;
  transform: rotate(0deg);
  transition: transform 0.3s ease-in-out;
}


.card-hide {
  border-radius: 0.375rem;
  border: none;
  display: block;
  position: absolute;
  text-decoration: none;
  z-index: 1;
  /* overflow: hidden; */
  width: 0px;
  height: 100%;
  background-color: #506070cd;
  top: 0;
  right: 0;
  transition: width 0.5s ease-out;
  display: flex;
  align-items: center;
  justify-content: end;
}

.card-show {
  width: 100%;
  height: 100%;
  background: #506070cd;
  /* opacity: .9; */
  /* padding-right: 50px; */
}

.btn-hide {
  /* opacity: 0; */
  transition: width 0.5s ease-in-out;
  /* transition: display 0.5s ease-in-out; */
  display: none !important;
  align-items: center;
  justify-content: center;
  width: 0px;
  height: 100%;
  /* padding-right: 40px; */
}

.btn-show {
  /* opacity: 1; */
  /* transition: display 10s ease-in-out; */
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 100%;
  /* padding-right: 40px; */
}

.btn-position {
  position: fixed;
  bottom: 15px;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: 10;
}

.bottom_card {
  position: relative;
  display: contents;
}

.bottom_card .bottom_btn {
  position: fixed;
  bottom: 15px;
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
  z-index: 1;
}

/* Navbar start */

.navbar-nav .nav-link {
  text-align: center;
  color: #000;
  font-size: 12px;
}

.navbar-nav .nav-link.active,
.navbar-nav .show>.nav-link {
  color: #fdc208;
}

.bg-navbar {
  /* background-color: #fdc208 !important; */
  background-color: #f5f5f8 !important;
  box-shadow: 0 2px 3px 2px rgba(0, 0, 0, .2);
}

.top-navbar {
  box-shadow: 0 1px 3px 0 #d4d9e1;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  background-color: #f5f5f8 !important;
}



/************************ float-button-css *******************/

.add-float {
  background-color: #fdc208;
  display: inline-block;
  position: relative;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
  cursor: pointer;
  outline: none;
  padding: 0;
  position: relative;
  -webkit-user-drag: none;
  font-weight: bold;
  color: #f1f1f1;
}

.add-float {
  width: 60px;
  height: 60px;
  overflow: hidden;
  -webkit-transition: width .5s ease;
  transition: width .5s ease;
  white-space: nowrap;
  /*this was suggested by jaunt, thanks */
  z-index: 10;
  display: flex;
  align-items: center;
}

.float-pos {
  position: fixed;
  right: 15px;
  bottom: 15px;
  transform: translate(0%, 0%);
}

.add-float-width {
  width: 93%;
  -webkit-transition: width 0.5s ease;
  transition: width 0.5s ease;
  border-radius: 30px;
  z-index: 10;
}

.nav-hide {
  opacity: 0;
  transition: opacity .5s ease;
}

.nav-show {
  opacity: 1;
  transition: opacity .5s ease;
}

/* .brandIcon-left {
  transform: rotate(0deg);
  transition: transform 0.3s ease-in-out;
}

.brandIcon-right {
  transform: rotate(180deg);
  transition: transform 0.3s ease-in-out;
} */

/* .brandIcon-right::before {
  content: "\f00d";
  font-family: FontAwesome !important;
  line-height: 0px;
}
.brandIcon-left::before {
  content: "\f0c9";
  font-family: FontAwesome !important;
  line-height: 0px;
} */

/* @media (max-width: 280px), (max-width: 480px), (max-width: 915px){
  .add-float:hover {
    width: 92%;
  }
} */


/* .create-invoice:hover::after {
  content: 'Create Invoice';
  padding-left: 4px;
} */


.light-btn.btn-primary {
  /* background-color: #fade8452;
  border-color: #fade8452; */
  /* color: #fdc208; */
  background-color: #ffffff;
  border-color: #ffffff;
  color: #000000;
  padding: .5rem 1rem;
  border-radius: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5;
  height: fit-content;
  border: none;
  /* border-radius: 0.375rem; */
  box-shadow: 0 1px 3px 0 #d4d9e1;
  /* overflow: hidden; */
}

.light-btn.btn-primary:not(:disabled):not(.disabled):active,
.light-btn.btn-primary:not(:disabled):not(.disabled).active,
.light-btn.show>.btn-primary.dropdown-toggle,
.light-btn.btn-primary:focus,
.light-btn.btn-primary:active,
.light-btn.btn-primary:focus-visible {
  color: #000000;
  background-color: #fdc208;
  border-color: #fdc208;
}

.light-btn.btn-primary:hover {
  background-color: #fdc208;
  border-color: #fdc208;
  color: #000000;
}

/* .unit_btn.btn-outline-primary {
  background-color: #ffffff;
  border-radius: 10px;
  border-color: rgba(0, 0, 0, 0.175);
  color: #506070;
}

.unit_btn.btn-outline-primary:hover {
  border-color: rgba(0, 0, 0, 0.175);
}

.unit_btn.btn-outline-primary:focus,
.unit_btn.btn-outline-primary:active,
.unit_btn.btn-outline-primary:focus-visible {
  color: #000;
  background-color: #fdc208;
  border-color: #fdc208;
} */


/* hambuger-menu (Bar-icon) */

:root {
  --animation-curve: cubic-bezier(1, 0, 0, 1);
  --bar-width: 22px;
  --bar-height: 3px;
  --hamburger-menu-gap: 4px;
  --foreground: #000;
  --background: #ffff;
  --hambuger-margin: 10px;
  --hambuger-padding: 20px;
  --animation-timing: 200ms ease-in-out;
  --hambuger-height: calc(var(--bar-height) * 3 + var(--hamburger-menu-gap) * 2);
}

.hamburger-menu {
  --x-width: calc(var(--hambuger-height) * 1.41421356237);
  display: flex;
  flex-direction: column;
  gap: var(--hamburger-menu-gap);
  width: 60px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  /* top: var(--hambuger-margin); */
  /* left: var(--hambuger-margin); */
  z-index: 10;
  cursor: pointer;
  padding: var(--hambuger-padding);
  background: #fdc40800;
  border-radius: 50%;
  /* margin-left: -3px; */
  height: 60px;
  align-items: center;
  justify-content: center;
}

.hamburger-menu::before,
.hamburger-menu::after,
.hamburger-menu input {
  content: "";
  width: var(--bar-width);
  height: var(--bar-height);
  background-color: var(--foreground);
  border-radius: 9999px;
  transition: opacity var(--animation-timing), width var(--animation-timing),
    rotate var(--animation-timing), translate var(--animation-timing),
    background-color var(--animation-timing);
  transform-origin: left center;
}

.hamburger-menu input {
  appearance: none;
  padding: 0;
  margin: 0;
  outline: none;
  pointer-events: none;
}

.hamburger-menu:has(input:checked)::before {
  rotate: 45deg;
  width: var(--x-width);
  translate: 0 calc(var(--bar-height) / -2);
}

.hamburger-menu:has(input:checked)::after {
  rotate: -45deg;
  width: var(--x-width);
  translate: 0 calc(var(--bar-height) / 2);
}

.hamburger-menu input:checked {
  opacity: 0;
  width: 0;
}

/************ filter badge ***************/

.filter-badge
{
  position: relative;
  padding: 5px 20px 5px 10px;
}
.filter-remove
{
  right:6px;
}

/* Navbar end */




/* ---------------------------------------------------
      Scroll Bar  STYLE
  ----------------------------------------------------- */

/* width */

* {
  /* IE 10+ */
  /* -ms-overflow-style: none; */

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: #506070 #e8ecef;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  cursor: pointer;
}

/* Track */

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #e8ecef;
  border-right: 0px solid transparent;
}


/* Handle */

::-webkit-scrollbar-thumb {
  background: #506070;
  border-radius: 10px;
}


/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #fdc208;
  ;
}


/* Handle on hover */

::-moz-scrollbar-thumb:hover {
  background: rgb(167, 159, 159);
}


/* width */

::-moz-scrollbar {
  width: 6px;
}


/* Track */

::-moz-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
  border-right: 2px solid #506070;
}


/* Handle */

::-moz-scrollbar-thumb {
  background: #506070;
  border-radius: 10px;
}

.foot-btn {
  font-size: 14px;
  border-radius: 10px;
  border: none;
  box-shadow: 0 1px 3px 0 #d4d9e1;
}


.form-check-input:checked {
  background-color: #fdc208;
  border-color: #fdc208;
}

.form-check-input:focus {
  border-color: #fdc208;
  outline: 0;
  box-shadow: 0 0 0 0.25rem #fdc20857;
}


.arrow-right {
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  /* position: absolute; */
  width: 1.25rem;
  height: 1.25rem;
  padding: 0px;
  overflow: hidden;
  top: 50%;
  /* right: 6px; */
  right: -3px;
  /* transform: translate(0%, -50%); */
  border: none;
  color: #000 !important;
  background-color: transparent !important;
}

/* 
.payment-height {
  overflow: hidden;
  height: calc(100vh - 60px) !important;
  overflow-y: auto;
  padding-bottom: 100px !important;
} */

.bg-welcomePage {
  /* background-color: unset !important; */
  background: linear-gradient(300deg, #ffffff 50%, #bc72c4 100%);
}

.bg-welcScreen {
  background-color: #fdc208;
  overflow: hidden;
  height: calc(100vh - 0px) !important;
  overflow-y: auto;
}

.foodFont {
  font-family: 'Droid Serif';
  font-weight: bold;
  font-size: 14px;
  /* font-size: 60px; */
  /* line-height: 0.9; */
  line-height: normal;
  /* color: #000000; */
  color: #fff;
  text-align: center;
  margin-bottom: 0px;
}

.welcFoodImage {
  background-image: url(./assets/images/jewellery.png);
  background-position: center;
  /* background-size: 90% 50%; */
  background-size: contain;
  /* background-size: 350px 335px; */
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  display: block;
}

/* .bg-welcScreen .btn-position,
.checkout .btn-position,
.cart .btn-position,
.payment .btn-position {
  bottom: -10px;
} */

.bg-welcScreen .bg-logo,
.enterNumber .bg-logo {
  width: 70px;
  height: 70px;
  background-color: #000;
  padding: 6px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

/* .bg-welcScreen::before {
  content: '';
  background: url(../src/assets/images/17372-ai.png) no-repeat center/contain;
  position: absolute;
  opacity: 1;
  top: 20px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  transform: translate(0%, -15%);
} */


/* .cart .card-height {
  max-height: calc(100vh - 85px) !important;
}

.checkout .card-height {
  max-height: calc(100vh - 85px) !important;
}

.payment .card-height {
  max-height: calc(100vh - 85px) !important;
  margin-bottom: 25px;
  padding: 0px;
} */
.dashboard-card-height {
  overflow: hidden;
  max-height: calc(100vh - 90px) !important;
  overflow-y: auto;
  height: 100vh;
}

.dashboard-card-heights {
  overflow: hidden;
  max-height: calc(100vh - 135px) !important;
  overflow-y: auto;
  height: 100vh;
}

/* slick slider */

.slick-prev:before,
.slick-next:before {
  /* opacity: 1 !important; */
  color: #000000 !important;
}

/* .slick-slide {
  padding: 0 8px;
  width: 200px !important;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  display: flex;
  width: 95%;
  margin: auto;
} */

.slick-next {
  right: -20px !important;
}

.slick-prev {
  left: -20px !important;
}

.slick-prev,
.slick-next {
  z-index: 1;
}

.slick-prev:before,
.slick-next:before {
  font-size: 16px !important;
  font-weight: bold;
  font-family: FontAwesome !important;
}

.slick-next:before {
  content: '\f054' !important;
}

.slick-prev:before {
  content: '\f053' !important;
}

.slick-dots {
  display: none !important;
}

/* .slick-initialized .slick-slide {
  width: 150px !important;
  overflow: hidden;
  white-space: nowrap;
} */

/* .item-nav-tabs .slick-slide {
  padding: 0 8px;
  width: 140px !important;
}

.slick-slider .nav-link {
  background: #46a846;
  padding: .5rem !important;
  justify-content: center;
  display: flex;
}

.item-nav-tabs .slick-slide .slick-active .nav-item .nav-link {
  background: #46a846 !important;
}

.item-nav-tabs .nav-item.show .nav-link,
.item-nav-tabs .nav-link.active {
  color: #000000;
  background: #fdc208;
} */


.item-nav-tabs {
  display: flex;
  flex-wrap: nowrap;
  /* overflow: hidden; */
  overflow-x: visible;
  font-size: 14px;
  border: none;
  /* margin: 0 12px; */
  width: 95%;
  margin: 0 auto;
}

.item-nav-tabs::-webkit-scrollbar {
  display: none;
}

/* .item-nav-tabs .nav-tabs .nav-link,  */
.item-nav-tabs .nav-link {
  border: none !important;
  /* color: #000; */
  color: #506070;
  white-space: nowrap;
  border-radius: 10px;
}

.item-nav-tabs .nav-item.show .nav-link,
.item-nav-tabs .nav-link.active {
  color: #000;
  background: #fdc208;
}

/* location list */

.list-group-item.active {
  color: #000;
  background-color: #fdc208;
  border-color: #fdc208;
}

.location-card-height {
  overflow: hidden;
  max-height: calc(100vh - 230px) !important;
  overflow-y: auto;
  height: 100vh;
}

.location::before {
  content: '';
  background: url(../src/assets/images/Map.svg) no-repeat top center/cover;
  position: absolute;
  opacity: .3;
  top: 15%;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  transform: translate(0%, -15%);
  backdrop-filter: blur(5px);
}

.droppable-bg-clr {
  background: #ebebeb;
}

.borderBottom {
  border-bottom: 1px dashed #f5f5f8;
}

.empty-badge {
  width: 10px;
  height: 10px;
  padding: 0px;
  float: left;
  align-items: center;
  display: flex !important;
}

.demo {
  /* flex-wrap: nowrap; */
  /* flex-direction: row; */
  /* display: inline-flex; */
  overflow: hidden;
  overflow-x: scroll;
}

.demo2 {
  flex-wrap: nowrap;
  flex-direction: row;
  display: inline-flex;
  /* overflow: hidden; */
  /* overflow-x: scroll; */
}

.dragBtn {
  background: transparent !important;
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  color: #a8a8a8d4 !important;
}

.dragBtn .btn {
  border: none !important;
  color: rgba(0, 0, 0, 0.5);
}

.dragBtn .dropdown-menu {
  border: none !important;
  box-shadow: 0 1px 3px 0 #d4d9e1;
  font-size: 14px;
}

.dragBtn .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0;
  vertical-align: 0;
  content: "";
  border-top: 0 solid;
  border-right: 0 solid transparent;
  border-bottom: 0;
  border-left: 0 solid transparent;

}

.dragBtn .btn:hover {
  color: #000000;
}

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
  /* color: #000000; */
  /* background-color: var(--bs-btn-active-bg); */
  /* border-color: var(--bs-btn-active-border-color); */
}



.brand-accor {

  --bs-accordion-bg: transparent !important;
}

.brand-accor .accordion-button {
  padding: 0;
  /* margin-bottom: 0.5rem; */
}

.brand-accor .accordion-button::after {
  margin-left: 5px;
}

.brand-accor .accordion-item {
  box-shadow: none;

}

.mapImg {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: start;
}


/*********** navbar sticky Icon ************/

.sticky-icon {
  z-index: 5;
  position: fixed;
  bottom: 15%;
  left: 0%;
  width: 220px;
  display: flex;
  flex-direction: column;
}

.sticky-icon a {
  /* transform: translate(-165px, 0px); */
  border-radius: 0px 50px 50px 0px;
  text-align: right;
  margin: 2px;
  text-decoration: none;
  padding: 6px;
  font-weight: bold;
  /* font-size:20px; */
  /* transition: all 1s; */
  background-color: #fdc208;
  color: #000 !important;
  animation: animate 30s linear infinite;
}

.sticky-icon a:focus {
  /* background-color: #506070; */
  /* color:#FFF !important; */
  transform: translate(-60px, 0px);
}

.sticky-icon .active {
  background-color: #506070 !important;
  color: #FFF !important;
  /* transform: translate(-165px, 0px); */
}

.sticky-icon a:focus i {
  transform: rotate(360deg);

}

/* .Delivery  {
	background-color:#2C80D3;
	color:#FFF;}
.Menu  {
	background-color:#FD1D1D;
	color:#FFF;}
	
.Pay  {
	background-color:#d34836;
	color:#FFF;}						 */
.sticky-icon a i {
  background-color: #FFF;
  height: 40px;
  width: 40px;
  color: #000 !important;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  margin-left: 15px;
  transition: all 1s;
  font-size: 20px;
  animation: spin 30s linear infinite;
}


.home-offcan {
  background: #f5f5f8 !important;
}


.sticky-icon a:nth-child(1) i,
.sticky-icon a:nth-child(1) {
  animation-delay: 0s;
  animation-duration: 10s;

}

.sticky-icon a:nth-child(2) i,
.sticky-icon a:nth-child(2) {
  animation-delay: 0s;
  animation-duration: 8s;
}

.sticky-icon a:nth-child(3) i,
.sticky-icon a:nth-child(3) {
  animation-delay: 0s;
  animation-duration: 12s;
}

.sticky-icon a:nth-child(4) i,
.sticky-icon a:nth-child(4) {
  animation-delay: 0s;
  animation-duration: 14s;
}

@keyframes animate {

  0% {
    transform: translate(-165px, 0px);

  }

  50% {
    transform: translate(-60px, 0px);
  }

  100% {
    transform: translate(-165px, 0px);
  }
}

@keyframes spin {

  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(0deg);
  }
}


/*********** home new search box ************/

.form-input {
  background-color: #ffffffff;
  border: none;
  border-radius: 10px;
  /* border-radius: calc(0.5 * 70px); */
  color: #000000ff;
  outline: 0;
  /* padding-right: 45px; */
  padding-left: 42px;
  position: relative;
  width: 100%;
  height: 40px;
  box-shadow: none;
  text-transform: capitalize;
}

.voice-icon-button {
  /* background-image: url(./assets/images/googlemic_clr_24px.svg); */
  background-color: transparent !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 21px 21px;
  border: none;
  border-radius: 0px;
  cursor: pointer;
  height: 65%;
  outline: 0;
  padding: 0 !important;
  pointer-events: auto;
  position: absolute;
  right: 14px;
  width: 26px;
  z-index: 1;
  top: 7px;
  color: #506070 !important;
  border-left: 1px solid #ced4da !important;
}

.search-icon {
  height: 100%;
  left: 8px;
  position: absolute;
  top: 0;
  z-index: 1;
  border: none;
  background: transparent !important;
  color: #506070 !important;
  padding: 0;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 32px;
}

.searchBox {
  height: 100%;
  position: relative;
  width: 100%;
  /* min-height: 40px; */
}

/******** Spinner with Checkbox *******/

.check-label {
  position: relative;
  height: 100px;
  width: 100px;
  display: inline-block;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 500%;
  border-left-color: #5cb85c;
  animation: rotate 1.2s linear infinite;
}

@keyframes rotate {
  50% {
    border-left-color: #9b59b6;
  }

  70% {
    border-left-color: #e67e22;
  }

  100% {
    transform: rotate(360deg);
  }
}

.check-label .check-icon {
  display: none;
}

.check-label .check-icon::after {
  position: absolute;
  content: '';
  top: 50%;
  left: 20px;
  transform: scaleX(-1) rotate(135deg);
  height: 48px;
  width: 26px;
  border-top: 4px solid #5cb85c;
  border-right: 4px solid #5cb85c;
  transform-origin: left top;
  animation: check-icon 0.8s ease;
}

@keyframes check-icon {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }

  20% {
    height: 0;
    width: 26px;
    opacity: 1;
  }

  40% {
    height: 48px;
    width: 26px;
    opacity: 1;
  }

  100% {
    height: 48px;
    width: 26px;
    opacity: 1;
  }
}

.check-input {
  display: none;
}

.check-input:checked~.check-label .check-icon {
  display: block;
}

.check-input:checked~.check-label {
  animation: none;
  border-color: #5cb85c;
  transition: border 0.5s ease-out;
}



/*********** Dashboard search box ************/

.search-Box {
  height: 100%;
  position: relative;
  width: 100%;
}

.search-Box .formInput {
  background-color: rgb(255, 255, 255);
  border: none;
  border-radius: calc(0.5 * 70px);
  color: #000000ff;
  outline: 0;
  padding-right: 20px;
  padding-left: 45px;
  position: relative;
  width: 100%;
  height: 60px;
  box-shadow: 0 1px 3px 0 #d4d9e1;
  text-transform: capitalize;
  font-size: 16px;
}

.search-Box .searchIcon {
  height: 60px;
  left: 11px;
  position: absolute;
  top: 0;
  z-index: 1;
  border: none;
  background: transparent !important;
  color: #000000 !important;
  padding: 0;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 32px;
  font-size: 16px;
}

.borderRight {
  height: 80%;
  display: flex;
  margin-left: 10px;
  /* opacity: .3; */
}

.borderRight::before {
  content: '';
  border-right: 1px solid #ced4da;
}


.inputs input {
  width: 40px;
  height: 53px
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0
}

/* 
:root {
  --border-width: 1px;
  --border-radius: 4px;
  --bg-color: #fff;
}

.dashed-border:before {
  content: "";
  position: absolute;
  border-top: 1px dashed #FF0000;
  top: calc(var(--border-width) * -1); 
  right: calc(var(--border-width) * -1);
  bottom: calc(var(--border-width) * -1);
  left: calc(var(--border-width) * -1);
} */



/******* timeline start ********/

.timeline-item {
  padding: 0;
  margin: 0;
}

.timeline-with-icons li:first-child {
  border-color: #506070;
}

.timeline-with-icons li {
  padding-left: 28px;
  position: relative;
  margin-left: 8px;
  border-left: 2px solid #506070;
  min-height: 35px;
  padding-top: 1px;
  list-style: none;
}

.timeline-with-icons li a {
  text-decoration: none;
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  color: #000;
}

.timeline-with-icons li .timeline-icon {
  height: 14px;
  width: 14px;
  position: absolute;
  border-radius: 100%;
  left: -8px;
  top: 0;
  background: #506070;
}

.timeline-with-icons li:last-child {
  border-color: transparent;
}


/******* timeline end ********/

/******* tracking timeline start ********/

.tracking-timeline-item {
  padding: 0;
  margin: 0;
}

.tracking-timeline-with-icons li {
  padding-left: 28px;
  position: relative;
  margin-left: 8px;
  border-left: 2px solid #bdbdbd;
  min-height: 40px;
  padding-top: 1px;
  list-style: none;
}

.tracking-timeline-with-icons li a {
  text-decoration: none;
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  color: #506070;
}

.tracking-timeline-with-icons li .tracking-timeline-icon {
  height: 14px;
  width: 14px;
  position: absolute;
  border-radius: 100%;
  left: -8px;
  top: 0;
  background: #bdbdbd;
}

.tracking-timeline-with-icons li.active .tracking-timeline-icon {
  background: #46a846;
}

.tracking-timeline-with-icons li.active {
  border-color: #46a846;
}

.tracking-timeline-with-icons li.active h4 {
  color: #000;
}

.tracking-timeline-with-icons li:last-child {
  border-color: transparent;
}


/******* tracking timeline end ********/


/******* dropdown ********/

.dropleft .dropdown-toggle:before,
.dropright .dropdown-toggle:before,
.dropup .dropdown-toggle:after,
.dropdown-toggle:after {
  content: "\f107";
  font-family: FontAwesome;
  border: 0;
  vertical-align: middle;
  margin-left: 0.5em;
  line-height: 1;
  font-size: 12px;
}

.dropdown-menu {
  font-size: 14px;
  border: 0;
  box-shadow: 0 10px 40px 0 rgba(32, 28, 69, 0.1);
  margin-top: 0;
  border-radius: 10px;
  overflow: hidden;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: #d8d8d852;
}

.rounded {
  border-radius: 10px !important;
}

.actionBtn.dropdown-toggle:after {
  display: none;
}


/* search */
.search-toggle.btn-primary {
  background-color: #fff !important;
  /* border-color: #506070; */
  color: #506070 !important;
  padding: 10px;
  border-radius: 30px;
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  height: -moz-fit-content;
  height: fit-content;
  border: none;
}

/* 
.search-toggle.btn-primary:not(:disabled):not(.disabled):active,
.search-toggle.btn-primary:not(:disabled):not(.disabled).active,
.search-toggle.show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #506070;
  border-color: #506070;
} */

/* .search-toggle.btn-primary:hover {
  background-color: #506070;
  color: #fff;
} */


.search-cancel.btn-primary {
  font-size: 14px;
  line-height: normal;
  /* height: -moz-fit-content;
  height: fit-content; */
  height: 100%;
  color: #506070 !important;
  background-color: #ffffff00 !important;
  border: none !important;
  left: 22px;
  position: absolute;
  top: 0;
  z-index: 1;
  padding: 0;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 32px;
}

.search-block {
  position: fixed;
  top: 0;
  right: -100%;
  width: 100%;
  height: 100%;
  z-index: 1111;
  overflow: hidden;
  background-color: #f5f5f8;
  transition: all 0.35s ease-in-out;
}

.search-block.is-active {
  top: 0;
  right: 0;
}

.search-form {
  display: flex;
  align-items: center;
  column-gap: 0.75rem;
  padding: 1rem;
  position: relative;
}

.search-input {
  display: block;
  width: 100%;
  height: 40px;
  outline: none;
  /* border-radius: 1.25rem !important; */
  border-radius: 10px !important;
  outline: 0;
  position: relative;
  box-shadow: 0 1px 3px 0 #d4d9e1 !important;
  /* border: 1px solid #ced4da !important; */
  text-transform: capitalize;
  /* padding: 0.5rem 1rem; */
  font-size: 14px;
  border: none;
  color: #000000ff;
  padding-right: 16px;
  padding-left: 42px;
}

.search-input::placeholder {
  color: #adadad !important;
  text-transform: lowercase;
}

.search-input:focus {
  border-color: #dee2e6 !important;
}

.search-input::-webkit-search-decoration,
.search-input::-webkit-search-cancel-button {
  display: none;
  visibility: hidden;
}

.rating-color {
  color: #fdc208 !important;
}

.small-ratings i {
  font-size: 12px;
  color: #cecece;
}

.divider {
  border-right: 1px solid rgba(0, 0, 0, 0.175);
  height: 15px;
  margin: 0 0.5rem;
  opacity: 1;
  overflow: hidden;
  display: list-item;
}

.categories-list {
  display: table;
  min-height: calc(100vh - 151px);
  width: 100%;
  height: fit-content;
}

.categories-list-table {
  display: flex;
  height: calc(-110px + 100vh);
  gap: 10px
}

.categories-list-left {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  scrollbar-width: none;
  width: 20%;
  z-index: 12;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 8px 0px;
}

.categories-list-right {
  display: flex;
  flex-direction: column;
  -webkit-box-flex: 1;
  flex-grow: 1;
  overflow-y: scroll;
  width: 80%;
  background-color: rgb(248, 248, 251);
  scrollbar-width: none;
}

.modal-content {
  border-radius: 20px;
}

.offcanvas_top {
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

.offcanvas_bottom {
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

.offcanvas_btn {
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  bottom: -50px;
  border-radius: 50px;
  line-height: normal;
}

.addQty_card {
  width: 92px;
}

.addQty_card .btn {
  padding: .5rem;
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-color: #dee2e6 !important;
  border-top: 1px solid #dee2e6;
  opacity: 1;
}

/* .details.collapse .btn .arrow-bottom i {
  transform: rotate(0deg);
  transition: transform 0.3s ease-in-out;
}

.details.collapse.show .btn.collapsed .arrow-top i {
  transform: rotate(180deg);
  transition: transform 0.3s ease-in-out;
} */

.detail_btn[aria-expanded="false"] span i {
  transform: rotate(0deg);
  transition: transform 0.3s ease-in-out;
}

.detail_btn[aria-expanded="true"] span i {
  transform: rotate(-180deg);
  transition: transform 0.3s ease-in-out;
}



.accor_btn .accordion-button {
  font-size: 14px;
  /* display: flex; */
}

.accor_btn .accordion-button .accor_img {
  width: 50px;
  transition: all 0.5s ease-in-out;
  margin-right: 15px;
}

.accor_btn .accordion-button .accor_text {
  width: 50%;
  display: block;
  transition: display 0.5s ease-in-out;
  opacity: 1;
  top: 0px;
  /* padding-right: 10px; */
  position: relative;
}

.accor_btn .accordion-button .accor_price {
  width: 50%;
  display: block;
  transition: display 0.5s ease-in-out;
  opacity: 1;
  top: 0px;
  position: relative;
}

@media (max-width: 400px) {
  .accor_btn .accordion-button .accor_price {
    padding-right: 20px;
  }
}

/* .accor_btn .accordion-button[aria-expanded="true"] {
  display: flex;
  justify-content: center;
  text-align: center;
  transition: justify-content 10s ease-in-out;
} */

.accor_btn .accordion-button[aria-expanded="true"] .accor_img {
  width: 80px;
  transition: width 0.5s ease-in-out;
  margin-right: 15px;
}

.accor_btn .accordion-button[aria-expanded="true"] .accor_text,
.accor_btn .accordion-button[aria-expanded="true"] .accor_price {
  display: block;
  transition: display 0.5s ease-in-out;
  opacity: 0;
  top: -50px;
  position: relative;
  width: 0px;
}

.accor_btn .accordion-button {
  /* pointer-events: none; */
}

.accor_btn .accordion-button::after {
  position: absolute;
  right: 10px;
  top: 24px;
  /* pointer-events: all; */
}

.accor_btn .accordion-button .accor_details {
  opacity: 1;
  display: none;
  transition: display 0.5s ease-in-out;
  top: -200px;
  position: relative;
  /* width: 0px; */
}

.accor_btn .accordion-button[aria-expanded="true"] .accor_details {
  display: block;
  opacity: 1;
  transition: display 0.5s ease-in-out;
  top: 0px;
  position: relative;
  width: 100%;
}

/* new accordion */
.accordion .accor_card {
  border: none;
  box-shadow: 0 1px 3px 0 #d4d9e1;
  margin-bottom: 8px;
  border-radius: 10px;
  background: #fff;
}

.accordion .accor_card .card-header {
  padding: 8px;
  padding-right: 30px;
  border: none;
  border-radius: 10px;
  background-color: transparent;
  display: flex;
  align-items: center;
}

.accordion .accor_card .card-header .accor_arrow {
  padding: 0;
  background: transparent;
  border: none;
  position: absolute;
  right: 10px;
  top: 24px;
}

.accordion .accor_card .card-header .accor_arrow::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  background-image: url(../src/assets/images/down-chevron.png);
  content: "";
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transform: rotate(0deg);
  transition: transform 0.2s ease-in-out !important;
  display: block;
  position: relative;
}

.accordion .accor_card .card-header .accor_arrow:not(.collapsed)::after {
  transform: rotate(-180deg);
}

.accordion .accor_card .card-header .accor_img {
  width: 50px;
  transition: all 0.5s ease-in-out;
  margin-right: 15px;
}

.accordion .accor_card .card-header .accor_img .accor_arrow[aria-expanded="true"] {
  width: 80px;
}

.accordion .accor_card .card-header .accor_text {
  width: 50%;
  display: block;
  transition: display 0.5s ease-in-out;
  opacity: 1;
  top: 0px;
  /* padding-right: 10px; */
  position: relative;

}

.accordion .accor_card .card-header .accor_price {
  width: 50%;
  display: block;
  transition: display 0.5s ease-in-out;
  opacity: 1;
  top: 0px;
  position: relative;
}

/* .accordion-button::after,
.accordion-button:not(.collapsed)::after {
  background-image: url(../src/assets/images/down-chevron.png);
} */

.badge_danger {
  background-color: rgb(255, 223, 223) !important;
  color: rgb(231, 56, 56);
}

/* .home_logo{
  width: 50px;
  height: 50px;
  background-color: #000;
  padding: 3px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
} */

.card_accordion {
  border: none;
  box-shadow: 0 1px 3px 0 #d4d9e1;
  margin-bottom: 8px;
  border-radius: 10px;
  background: #fff;
  position: relative;
}

.card_accordion .card-header {
  padding: 8px;
  margin-right: 36px;
  border: none;
  border-radius: 10px;
  background-color: transparent;
  display: flex;
  align-items: center;
  /* position: relative; */
}

.card_accordion .card-body {
  padding: 8px;
}

.card_accordion .card-header .arrow_btn {
  padding: 0;
  background: transparent;
  border: none;
  position: absolute;
  right: 10px;
  top: 22px;
  margin-left: auto;
  display: block;
}

.card_accordion .card-header .arrow_btn::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  background-image: url(../src/assets/images/down-chevron.png);
  content: "";
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transform: rotate(0deg);
  transition: transform 0.2s ease-in-out !important;
  display: block;
  position: relative;
}

.card_accordion .card-header .arrow_btn[aria-expanded="true"]::after {
  transform: rotate(-180deg);
}


.card_accordion .card-header .accor_img {
  width: 45px;
  height: 45px;
  transition: all 0.5s ease-in-out;
  /* margin-right: 15px; */
}

.card_accordion .card-header .accor_img_lg {
  width: 60px;
  height: 60px;
}

.card_accordion .card-header .accor_text_one {
  width: 55%;
  display: block;
  transition: display 0.5s ease-in-out;
  opacity: 1;
  top: 0px;
  padding-right: 10px;
  position: relative;
  font-size: 12px;
}

@media (min-width: 280px) and (max-width: 410px) {
  .card_accordion .card-header .accor_text_one {
    width: 30%;
  }

  .card_accordion .card-header .accor_text_two {
    width: 80%;
    display: block;
  }

}

.card_accordion .card-header .accor_price {
  width: 45%;
  display: block;
  transition: display 0.5s ease-in-out;
  opacity: 1;
  top: 0px;
  position: relative;
}




/******** Spin and Check *******/

.check-container {
  width: 6.25rem;
  height: 7.5rem;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
  margin: 30px auto;
}

.check-container .check-background {
  width: 100%;
  height: calc(100% - 1.25rem);
  background: linear-gradient(to bottom right, #5de593, #41d67c);
  box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
  transform: scale(0.84);
  border-radius: 50%;
  animation: animateContainer 0.75s ease-out forwards 0.75s;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}

.check-container .check-background svg {
  width: 65%;
  transform: translateY(0.25rem);
  stroke-dasharray: 80;
  stroke-dashoffset: 80;
  animation: animateCheck 0.35s forwards 1.25s ease-out;
}

.check-container .check-shadow {
  bottom: calc(-15% - 5px);
  left: 0;
  border-radius: 50%;
  background: radial-gradient(closest-side, #49da83, transparent);
  animation: animateShadow 0.75s ease-out forwards 0.75s;
}

@keyframes animateContainer {
  0% {
    opacity: 0;
    transform: scale(0);
    box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
  }

  25% {
    opacity: 1;
    transform: scale(0.9);
    box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
  }

  43.75% {
    transform: scale(1.15);
    box-shadow: 0px 0px 0px 43.334px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
  }

  62.5% {
    transform: scale(1);
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 21.667px rgba(255, 255, 255, 0.25) inset;
  }

  81.25% {
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset;
  }

  100% {
    opacity: 1;
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset;
  }
}

@keyframes animateCheck {
  from {
    stroke-dashoffset: 80;
  }

  to {
    stroke-dashoffset: 0;
  }
}

@keyframes animateShadow {
  0% {
    opacity: 0;
    width: 100%;
    height: 15%;
  }

  25% {
    opacity: 0.25;
  }

  43.75% {
    width: 40%;
    height: 7%;
    opacity: 0.35;
  }

  100% {
    width: 85%;
    height: 15%;
    opacity: 0.25;
  }
}

/******** Spin and close *******/

.close-container {
  width: 6.25rem;
  height: 7.5rem;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
  margin: 30px auto;
}

.close-container .close-background {
  width: 100%;
  height: calc(100% - 1.25rem);
  background: linear-gradient(to bottom right, #ed5575, #d54564);
  box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
  transform: scale(0.84);
  border-radius: 50%;
  animation: animateContainer 0.75s ease-out forwards 0.75s;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}

.close-container .close-background svg {
  width: 50%;
  transform: translateY(0.25rem);
  stroke-dasharray: 80;
  stroke-dashoffset: 80;
  animation: animateCheck 0.35s forwards 1.25s ease-out;
}

.close-container .close-shadow {
  bottom: calc(-15% - 5px);
  left: 0;
  border-radius: 50%;
  background: radial-gradient(closest-side, #ed5575, transparent);
  animation: animateShadow 0.75s ease-out forwards 0.75s;
}


/******** Spin and warning *******/

.warning-container {
  width: 6.25rem;
  height: 7.5rem;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
  margin: 30px auto;
}

.warning-container .warning-background {
  width: 100%;
  height: calc(100% - 1.25rem);
  background: linear-gradient(to bottom right, #ffc107, #fdc208);
  box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
  transform: scale(0.84);
  border-radius: 50%;
  animation: animateContainer 0.75s ease-out forwards 0.75s;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}

.warning-container .warning-background svg {
  width: 50%;
  transform: translateY(0.25rem);
  stroke-dasharray: 80;
  stroke-dashoffset: 80;
  animation: animateCheck 0.35s forwards 1.25s ease-out;
}

.warning-container .warning-shadow {
  bottom: calc(-15% - 5px);
  left: 0;
  border-radius: 50%;
  background: radial-gradient(closest-side, #ffc107, transparent);
  animation: animateShadow 0.75s ease-out forwards 0.75s;
}

/* modal expandIn effect */
.modal .modal-dialog.modal-dialog-centered {
  transform: scale(0) !important;
  -webkit-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.modal.show .modal-dialog.modal-dialog-centered {
  transform: scale(1) !important;
}

@media (min-width: 280px) and (max-width: 480px),
(min-width: 480px) and (max-width: 768px),
(min-width: 768px) and (max-width: 1024px) {}


/* category_list */

.category_list .list-group {
  border-radius: 10px;
  box-shadow: 0 1px 3px 0 #d4d9e1;
  padding: 0.5rem 0;
  background: white;
  height: 100vh;
}

.category_list .list-group .list-group-item:first-child,
.category_list .list-group .list-group-item:last-child {
  border-radius: 0px;
}

.category_list .list-group .list-group-item {
  border: none;
  /* border-top: none; */
  /* border-left: none; */
  /* border-right: none; */
  overflow: hidden;
  color: #506070;
}

/* .category_list .list-group .list-group-item:first-child{
  border-top: none;
}
.category_list .list-group .list-group-item:last-child{
  border-bottom: none;
} */

.category_list {
  overflow: hidden;
  height: calc(100vh - 213px) !important;
  overflow-y: auto;
  padding-bottom: 80px;
  scrollbar-width: none;
  /* width: 100px;
  float: left;
  margin-right: 8px; */
}

.category_list::-webkit-scrollbar {
  display: none;
}

.category_list.dine_list {
  height: 100% !important;
}

.category_list.dine_list .list-group {
  height: 100% !important;
}

.category_list .list-group .list-group-item .right_border {
  display: none !important;
}

.category_list .list-group .list-group-item.active .right_border {
  color: #000;
  background-color: #fdc208;
  border-color: #fdc208;
  display: flex !important;
  width: 15px;
  position: absolute;
  font-size: 10px;
  height: 77%;
  top: 8px;
  right: -11px;
  color: transparent;
  border-radius: 4px;

}

.category_list .list-group .list-group-item.active {
  color: #000;
  background-color: #ffffff;
  /* border-color: #fdc208; */
  /* border-top-color: #dee2e6;
  border-bottom-color: #dee2e6; */
  margin: 0px;
}

.category_list .list-group .list-group-item .list_img_one,
.category_list .list-group .list-group-item .list_img_two,
.category_list .list-group .list-group-item .list_img_three,
.category_list .list-group .list-group-item .list_img_four {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 40px;
  height: 40px;
  display: block;
  transition: all 0.1s ease-in-out;
  margin: 0 auto;
}

.category_list .list-group .list-group-item.active .list_img_one {
  background-image: url('./assets/images/SVG/dineblack.svg');
}

.category_list .list-group .list-group-item .list_img_one {
  background-image: url('./assets/images/SVG/dinegrey.svg');
}

.category_list .list-group .list-group-item.active .list_img_two {
  background-image: url('./assets/images/SVG/deliveryblack.svg');
}

.category_list .list-group .list-group-item .list_img_two {
  background-image: url('./assets/images/SVG/deliverygrey.svg');
}

.category_list .list-group .list-group-item.active .list_img_three {
  background-image: url('./assets/images/SVG/packingblack.svg');
}

.category_list .list-group .list-group-item .list_img_three {
  background-image: url('./assets/images/SVG/packinggrey.svg');
}

.category_list .list-group .list-group-item.active .list_img_four {
  background-image: url('./assets/images/SVG/drivethrublack.svg');
}

.category_list .list-group .list-group-item .list_img_four {
  background-image: url('./assets/images/SVG/drivethrugrey.svg');
}

.shadow_sm {
  box-shadow: 0 1px 3px 0 #d4d9e1;
}

.my_modal .modal-content {
  background: #f5f5f8;
}

/* Seat Reservation System */
.grid_seat td {
  width: 36px;
  height: 48px;
  border: 1px solid;
  text-align: center;
  float: left;
  margin: 5px;
  display: flex;
  flex-direction: column;
  background-color: #fade8452;
  border-color: #fade8452;
  color: #000;
  border-radius: 10px;
}

.grid_seat td:hover {
  /* opacity: 0.7; */
  cursor: pointer;
  background-color: #fdc208;
  border-color: #fdc208;
  color: #000;
}

.reserved {
  background-color: #fdc208 !important;
  border-color: #fdc208 !important;
  color: #000 !important;
  display: inline-block;
}

.selected {
  background: #506070 !important;
  border-color: #506070 !important;
  color: #fff !important;
  display: inline-block;
}

.reserved::after {
  content: "R";
}

.selected::after {
  content: "S";
}

.available::after {
  content: "A";
}

.available {
  color: #506070 !important;
}

li {
  list-style: none;
}


@media (min-width: 280px) and (max-width: 480px) {
  .fs_xs_10 {
    font-size: 10px !important;
  }

}

.size_block {
  width: 50px;
}

.inner_card {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  gap: 8px;
}

.inner_card .btn {
  width: 80px !important;
}

/* map_list_group */

.map_list_group.list-group {
  border-radius: 0px;
  /* box-shadow: 0 1px 3px 0 #d4d9e1; */
  /* padding: 0.5rem 0; */
  background: #fff;
  overflow-y: auto;
  height: calc(100vh - 345px) !important;
}

.map_list_group.list-group .list-group-item:first-child {
  /* border-top-left-radius: 10px;
  border-top-right-radius: 10px; */
  border-radius: 0px;
}

.map_list_group.list-group .list-group-item:last-child {
  /* border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px; */
  border-radius: 0px;
  border-bottom: none;
}

.map_list_group.list-group .list-group-item {
  border: none;
  border-bottom: 1px solid #dee2e6;
  padding: 1rem;
}


.searchBar_block {
  height: calc(100vh - 74px) !important;
  overflow: hidden;
  overflow-y: auto;
}

.brand_img {
  width: 50px;
  height: 50px !important;
  padding: 6px !important;
  border-radius: 50% !important;
  background: #000 !important;
  overflow: hidden;
}

.brand_img img {
  width: 100%;
  margin: auto;
  border-radius: 50%;
}


/* toast */

.toast-position {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  width: 100%;
  padding: 20px 20px 18px 20px;
  transition: background .15s linear;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.toast-bg {
  background: #f5f5f8;
}

.toast_icon {
  border-radius: 10px;
  /* border-radius: 0.375rem; */
  background: #ffffff;
  padding: 12px;
  margin-right: 12px;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  backdrop-filter: blur(5px);
}

.toast_expand_btn {
  padding: 5px 10px;
  font-size: 12px;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 5px !important;
  pointer-events: auto;
  background: #506070 !important;
  border: none;
}

.toast-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  /* height: 100vh; */
  background-color: #000;
}

.toast-backdrop.show {
  opacity: .5 !important;
  height: 100% !important;
}

.toast-backdrop.toast-fade {
  opacity: 0;
  height: 0;
}

.toast-fade {
  transition: opacity .15s linear;
}

.toast-position .toast:last-child {
  margin-bottom: 0 !important;
}

.radio-right {
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  /* position: absolute;  */
  width: 1.25rem;
  height: 1.25rem;
  padding: 0px;
  /* overflow: hidden;  */
  top: 50%;
  /* right: 6px;  */
  right: -3px;
  /* transform: translate(0%, -50%);  */
  border: none;
  color: #000 !important;
  background-color: transparent !important;
}

.disable_arrow .accordion-button::after {
  content: none;
}

.placeholder-glow .btn:disabled,
.placeholder {
  opacity: .2;
}


@keyframes placeholder-glow {
  50% {
    opacity: 0.1;
  }
}



/* Home Page Accordion  */
.new_accordion {
  border: none;
  box-shadow: 0 1px 3px 0 #d4d9e1;
  margin-bottom: 8px;
  border-radius: 10px;
  background: #fff;
  position: relative;
}

.new_accordion .accordion-header {
  padding: 8px;
  margin-right: 36px;
  border: none;
  border-radius: 10px;
  background-color: transparent;
  display: flex;
  align-items: center;
  /* position: relative; */
}

.new_accordion .accordion-body {
  padding: 8px;
}

.new_accordion .accordion-header .arrow_btn {
  padding: 0;
  position: absolute;
  right: 10px;
  top: 22px;
  width: fit-content;
}

.new_accordion .accordion-header .arrow_btn::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  background-image: url(../src/assets/images/down-chevron.png);
  content: "";
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transform: rotate(0deg);
  transition: transform 0.2s ease-in-out !important;
  display: block;
  position: relative;
}

.new_accordion .accordion-header .arrow_btn[aria-expanded="true"]::after {
  transform: rotate(-180deg);
}

.new_accordion .accordion-header .accor_img {
  width: 45px;
  height: 45px;
  transition: all 0.5s ease-in-out;
  /* margin-right: 15px; */
}

.new_accordion .accordion-header .accor_img_lg {
  width: 60px;
  height: 60px;
}

.new_accordion .accordion-header .accor_text_one {
  width: 55%;
  display: block;
  transition: display 0.5s ease-in-out;
  opacity: 1;
  top: 0px;
  padding-right: 10px;
  position: relative;
  font-size: 12px;
}

@media (min-width: 280px) and (max-width: 410px) {
  .new_accordion .accordion-header .accor_text_one {
    width: 30%;
  }

  .new_accordion .accordion-header .accor_text_two {
    width: 80%;
    display: block;
  }

}

.new_accordion .accordion-header .accor_price {
  width: 45%;
  display: block;
  transition: display 0.5s ease-in-out;
  opacity: 1;
  top: 0px;
  position: relative;
}

.img_size {
  width: 70px !important;
  height: 70px !important;
}

.offer_carousel .carousel-indicators [data-bs-target] {
  width: 3px;
}

.offer_carousel .carousel-indicators {
  margin-bottom: 0px;
}

.offer_carousel .carousel-control-next,
.offer_carousel .carousel-control-prev {
  display: none;
}


.star_rating_icon {
  width: 23px;
  height: 20px;
  padding: 0px;
  position: relative;
  /* overflow: hidden; */
  background-color: transparent;
  margin-right: 8px;
  cursor: pointer;
}

.star_rating_icon:last-child {
  margin-right: 0px;
}

.star_rating_icon input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.star_rating_icon .star_icon {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1;
  font-size: 20px;
}

.progress_bar {
  width: 100%;
  height: 6px !important;
}

.progress_bar .bg-danger {
  background: #ed5575 !important;
}

.progress_bar .bg-success {
  background: #46a846 !important;
}

.reviewModal {
  overflow-y: auto;
  height: calc(75vh - 100px) !important;
  padding-bottom: 70px;
}

.profile_box {
  width: 70px;
  height: 70px;
  position: relative;
  margin: auto;
}

.profile_img {
  width: 70px;
  height: 70px;
  /* padding: 6px; */
  border-radius: 50%;
  background: #506070;
  overflow: hidden;
  border: 2px solid #506070;
  box-shadow: 0 1px 3px 0 #d4d9e1;
  position: relative;
}

.profile_img img {
  width: 100%;
  margin: auto;
  object-fit: cover;
}

.profile_box input {
  display: none;
}

.profile_edit_btn {
  width: 25px;
  height: 25px;
  bottom: -13px;
  right: -13px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #506070 !important;
}


/* category_list HOME */

.category_list_left {
  height: 100%;
}

.category_list_right {
  overflow: hidden;
  height: calc(100vh - 214px) !important;
  overflow-y: auto;
  padding-bottom: 80px;
}

.list-group-scroll {
  overflow: hidden;
  height: calc(100vh - 238px);
  overflow-y: auto;
  padding-bottom: 70px;
  scrollbar-width: none;
}

.category_list_left .list-group {
  border-radius: 10px;
  box-shadow: 0 1px 3px 0 #d4d9e1;
  padding: 0.5rem 0;
  background: white;
  /* height: 100vh; */
}

.category_list_left .list-group .list-group-item:first-child,
.category_list_left .list-group .list-group-item:last-child {
  border-radius: 0px;
}

.category_list_left .list-group .list-group-item {
  border: none;
  /* border-top: none; */
  /* border-left: none; */
  /* border-right: none; */
  overflow: hidden;
  color: #506070;
}

@media (min-width: 280px) and (max-width: 480px) {
  .left_width {
    width: 20%;
  }

  .category_list_right {
    width: 80%;
  }

}

.category_list_left::-webkit-scrollbar {
  display: none;
}

.category_list_left .list-group .list-group-item .right_border {
  display: none !important;
}

.category_list_left .list-group .list-group-item.active .right_border {
  color: #000;
  background-color: #fdc208;
  border-color: #fdc208;
  display: flex !important;
  width: 15px;
  position: absolute;
  font-size: 10px;
  height: 80%;
  top: 8%;
  right: -11px;
  color: transparent;
  border-radius: 4px;

}

.category_list_left .list-group .list-group-item.active {
  color: #000;
  background-color: #ffffff;
  /* border-color: #fdc208; */
  /* border-top-color: #dee2e6;
  border-bottom-color: #dee2e6; */
  margin: 0px;
}

.category_list_left .list-group .list-group-item .list_img_one,
.category_list_left .list-group .list-group-item .list_img_two,
.category_list_left .list-group .list-group-item .list_img_three,
.category_list_left .list-group .list-group-item .list_img_four {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 40px;
  height: 40px;
  display: block;
  transition: all 0.1s ease-in-out;
  margin: 0 auto;
}

.category_list_left .list-group .list-group-item.active .list_img_one {
  background-image: url('./assets/images/SVG/dineblack.svg');
}

.category_list_left .list-group .list-group-item .list_img_one {
  background-image: url('./assets/images/SVG/dinegrey.svg');
}

.category_list_left .list-group .list-group-item.active .list_img_two {
  background-image: url('./assets/images/SVG/deliveryblack.svg');
}

.category_list_left .list-group .list-group-item .list_img_two {
  background-image: url('./assets/images/SVG/deliverygrey.svg');
}

.category_list_left .list-group .list-group-item.active .list_img_three {
  background-image: url('./assets/images/SVG/packingblack.svg');
}

.category_list_left .list-group .list-group-item .list_img_three {
  background-image: url('./assets/images/SVG/packinggrey.svg');
}

.category_list_left .list-group .list-group-item.active .list_img_four {
  background-image: url('./assets/images/SVG/drivethrublack.svg');
}

.category_list_left .list-group .list-group-item .list_img_four {
  background-image: url('./assets/images/SVG/drivethrugrey.svg');
}




/* product detail page */

.preview {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background: 'rgba(0, 0, 0, 0.8)'; */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1045;
}

.preview img {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
}

.preview-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  /* height: 100vh; */
  background-color: #000;
}

.preview-backdrop.show {
  opacity: .5 !important;
  height: 100% !important;
}

.preview-backdrop.preview-fade {
  opacity: 0;
  height: 0;
}

.preview-fade {
  transition: opacity .15s linear;
}


.slider-list .slick-track {
  display: flex;
  gap: 10px;
  height: 54px;
}

.download-input {
  position: absolute;
  top: 3px;
  right: 12px;
  z-index: 55;
}

.new-logo {
  width: 50px;
  height: 50px;
  background-color: #000;
  padding: 6px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  /* margin: 0 auto; */
}

.new-logo .logo {
  border-radius: 50%;
}

.bg-logo .logo {
  border-radius: 50%;
}

.shadow-none {
  box-shadow: none !important;
}


.welcomepage-card-height {
  overflow: hidden;
  max-height: calc(100vh - 0px);
  overflow-y: auto;
  height: 100vh;
}

.bg-gradient-blue {
  background: linear-gradient(180deg, rgba(80, 96, 112, 1) 0%, rgba(2, 0, 36, 0) 100%);
}

.bg-gradient-red {
  background: linear-gradient(180deg, #ed5575 0%, rgba(2, 0, 36, 0) 100%);
}

.bg-gradient-green {
  background: linear-gradient(180deg, #46a846 0%, rgba(2, 0, 36, 0) 100%);
}

.card-position {
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translate(-50%, -0%);
}

.cardImg {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 auto;
  width: 120px;
  height: 120px;
  border: 1px solid #FBFBFB;
  background: #fff;
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
  /* box-shadow: 0px 21px 44px rgba(0, 0, 0, 0.08); */
  transition: box-shadow 0.3s ease-out, transform 0.3s ease-out;
}


.cardImg:hover {
  transform: translate3d(0, -10px, 0);
  box-shadow: 0px 21px 44px rgba(0, 0, 0, 0.08);
}

.cardImg img {
  width: 70px;
  height: 70px;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

.carousel-indicators {
  margin-bottom: 0px;
}

.carousel-indicators [data-bs-target] {
  width: 3px;
}

.carousel-control-next,
.carousel-control-prev {
  width: 10%;
}

.welcomePage-Card {
  border-radius: 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border: none;
  /* height: 100%; */
  background-color: #ffffff;
  height: calc(100vh - 171px)
}

.DroidSerif {
  font-family: 'Droid Serif';
}

@media screen and (max-width:992px) {
  .product-thumbnail-slider {
    margin-top: 20px;
    height: auto;
  }



  .cardImg {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0 auto;
    width: 70px;
    height: 70px;
    border: 1px solid #FBFBFB;
    background: #fff;
    /* box-shadow: 0px 21px 44px rgba(0, 0, 0, 0.08); */
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
    transition: box-shadow 0.3s ease-out, transform 0.3s ease-out;
  }

  .cardImg:hover {
    transform: translate3d(0, -10px, 0);
    box-shadow: 0px 21px 44px rgba(0, 0, 0, 0.08);
  }

  .cardImg img {
    width: 40px;
    height: 40px;
  }
}

@media screen and (min-width:992px) {
  .product-thumbnail-slider {
    height: 520px;
  }
}

@media screen and (min-width:1200px) {
  .product-thumbnail-slider {
    height: 740px;
  }
}


@media screen and (max-width:1024px) {

  .banner-ad {
    min-height: 200px;
  }
  .banner-product {
    min-height: 300px;
  }
}

@media (min-width: 1025px) and (max-width: 1280px) {
  
  .banner-ad {
    min-height: 200px;
  }
  .banner-product {
    min-height: 300px;
  }
}
@media (min-width: 667px) and (max-width: 767px) {
  
  .banner-ad {
    min-height: 200px;
  }
  .banner-product {
    min-height: 300px;
  }
  
}
@media (min-width: 320px) and (max-width: 480px) {
  
  
  .banner-ad {
    min-height: 100px;
  }
  .banner-product {
    min-height: 160px;
  }
  
}

@media only screen and (min-device-width: 480px) 
                   and (max-device-width: 640px) 
                   and (orientation: landscape) {

                    .banner-ad {
                      height: 200px;
                     }
}